<template>
  <div class="change-password">
    <h1 class="change-password__title second-title">
      {{ $t("placeholder.editPassword") }}
    </h1>
    <VeeForm @submit="onSubmit" class="change-password__form">
      <div class="change-password__wrapper">
        <div class="change-password__items">
          <div class="change-password__item">
            <div>
              <VeeField
                name="currentPassword"
                rules="required"
                :type="showPass ? 'password' : 'text'"
                class="change-password__input main-input"
                :placeholder="$t('placeholder.currentPassword')"
                v-model="passwords.old"
              />
              <span class="eye" @click="showPass = !showPass">
                <img
                  :src="
                    require(`@/assets/icons/${
                      !showPass ? 'eyeshow' : 'eyehide'
                    }.png`)
                  "
                />
              </span>
            </div>
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="currentPassword"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="change-password__item">
            <div>
              <VeeField
                :type="showPass1 ? 'password' : 'text'"
                name="password"
                ref="password"
                rules="required|min:6"
                class="change-password__input main-input"
                :placeholder="$t('placeholder.password')"
                v-model="passwords.new"
              />
              <span class="eye" @click="showPass1 = !showPass1">
                <img
                  :src="
                    require(`@/assets/icons/${
                      !showPass1 ? 'eyeshow' : 'eyehide'
                    }.png`)
                  "
                />
              </span>
            </div>
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="password"
              ></VeeErrorMessage>
            </transition>
          </div>
          <div class="change-password__item">
            <div>
              <VeeField
                name="passwordConfirm"
                :type="showPass2 ? 'password' : 'text'"
                rules="required|min:6"
                class="change-password__input main-input"
                :placeholder="$t('placeholder.confirmPassword')"
                v-model="passwords.newConfirm"
              />
              <span class="eye" @click="showPass2 = !showPass2">
                <img
                  :src="
                    require(`@/assets/icons/${
                      !showPass2 ? 'eyeshow' : 'eyehide'
                    }.png`)
                  "
                />
              </span>
            </div>
            <transition name="error">
              <VeeErrorMessage
                class="error-message"
                name="passwordConfirm"
              ></VeeErrorMessage>
            </transition>
          </div>
        </div>
        <transition name="error">
          <span class="error-message">{{ errorMessage }}</span>
        </transition>
      </div>
      <button class="change-password__btn main-btn" type="submit">
        {{ $t("buttons.save") }}
      </button>
    </VeeForm>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      errorMessage: "",
      showPass: true,
      showPass1: true,
      showPass2: true,
      passwords: {
        old: "",
        new: "",
        newConfirm: "",
      },
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    onSubmit() {
      if (this.passwords.new !== this.passwords.newConfirm) {
        this.toast.error(this.$t("errors.passDont"));
      } else {
        axios
          .post(
            "/V1/password-update",
            {
              old_password: this.passwords.old,
              new_password: this.passwords.new,
              new_password_confirmation: this.passwords.newConfirm,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          )
          .then(() => {
            this.passwords = {
              old: "",
              new: "",
              newConfirm: "",
            };
            this.$emit("success");
          })
          .catch((errors) => {
            this.errorMessage = errors.response.data.message;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.change-password {
  @media (min-width: $mobileSmall + px) {
    padding: 60px 84px 72px !important;
  }
  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
  &__title,
  &__wrapper {
    margin: 0 0 35px 0;
  }
  &__item {
    &:not(:last-child) {
      margin: 0 0 20px 0;
    }
    div {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #77757f;
      .main-input {
        border: none;
        outline: none;
      }
      .eye {
        padding: 0 12px;
        img {
          width: 16px;
        }
      }
    }
  }
  &__btn {
    width: 100%;
  }
}
</style>