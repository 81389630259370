<template>
  <div class="personal-orders">
    <h2 class="personal-orders__title second-title"></h2>
    <div class="personal-orders__body">
      <div class="personal-orders__tabs">
        <TabItem
          :selected="selectedTab"
          :tabItems="tabs"
          @setTab="selectTab"
          :type="'border'"
        />
        <TabBlock :isSelected="selectedTab === tabs[0].id">
          <div class="personal-orders__blocks">
            <div
              class="personal-orders__block"
              v-for="(order, id) in orderas"
              :key="id"
              :class="{ line: orders.length > 0 }"
            >
              <div class="personal-orders__item">
                {{ $t("orders.status") }}
                <span>{{ order.status }}</span>
              </div>
              <div class="personal-orders__item">
                <span>
                  {{
                    $t("orders.nomer", {
                      nomer: order.id
                    })
                  }}</span
                >
              </div>
              <div class="personal-orders__item">
                {{ $t("orders.sum") }}
                <span>{{ formatPrice(order.total_price) }} / </span>
                <span v-if="order.pay_status">{{ $t("orders.paid") }}</span>
                <span v-if="!order.pay_status">{{ $t("orders.noPaid") }}</span>
              </div>
              <div class="personal-orders__item">
                {{ $t("nav.delivery") }}:
                <span v-if="order.delivery === 'delivery'">{{
                  $t("orders.courier")
                }}</span>
                <span v-if="order.delivery === 'self'">{{
                  $t("orders.courier")
                }}</span>
                <span>{{ order.deliveryAmount }}</span>
              </div>
              <div class="personal-orders__item">
                {{ $t("orders.goods") }}:
                <div class="personal-orders__products">
                  <div
                    class="personal-orders__product"
                    v-for="(product, i) in order.products"
                    :key="i"
                  >
                    <span v-if="product.count > 0"
                      >{{
                        i +
                        1 +
                        ") " +
                        product.product_name +
                        ", " +
                        product.count
                      }}
                      шт.</span
                    >
                    <span v-else
                      >{{
                        i +
                        1 +
                        ") " +
                        product.product_name +
                        ", " +
                        product.box_count
                      }}
                      уп.
                    </span>
                    <span v-if="product.funds_1_bonus > 0"
                      >+
                      {{
                        $t("freeStock", { value: product.funds_1_bonus })
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link to="/">
            <button class="personal-orders__btn main-btn">
              {{ $t("continueShopping") }}
            </button></router-link
          >
        </TabBlock>
        <TabBlock :isSelected="selectedTab === tabs[1].id">
          <div class="personal-orders__blocks">
            <div
              class="personal-orders__block"
              v-for="(order, id) in orderas"
              :key="id"
              :class="{ line: orders.length > 0 }"
            >
              <div class="personal-orders__item">
                {{ $t("orders.status") }}
                <span>{{ order.status }}</span>
              </div>
              <div class="personal-orders__item">
                <span>
                  {{
                    $t("orders.nomer", {
                      nomer: order.id,
                      date: order.date,
                    })
                  }}</span
                >
              </div>
              <div class="personal-orders__item">
                {{ $t("orders.sum") }}
                <span>{{ formatPrice(order.total_price) }} / </span>
                <span v-if="order.pay_status">{{ $t("orders.paid") }}</span>
                <span v-if="!order.pay_status">{{ $t("orders.noPaid") }}</span>
              </div>
              <div class="personal-orders__item">
                {{ $t("delivery") }}:
                <span v-if="order.delivery === 'delivery'">{{
                  $t("orders.courier")
                }}</span>
                <span v-if="order.delivery === 'self'">{{
                  $t("orders.courier")
                }}</span>
                <span>{{ order.deliveryAmount }}</span>
              </div>
              <div class="personal-orders__item">
                {{ $t("orders.goods") }}:
                <div class="personal-orders__products">
                  <div
                    class="personal-orders__product"
                    v-for="(product, i) in order.products"
                    :key="i"
                  >
                    <span v-if="product.count > 0"
                      >{{
                        i +
                        1 +
                        ") " +
                        product.product_name +
                        ", " +
                        product.count
                      }}
                      шт.</span
                    >
                    <span v-else
                      >{{
                        i +
                        1 +
                        ") " +
                        product.product_name +
                        ", " +
                        product.box_count
                      }}
                      уп.</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link to="/">
            <button class="personal-orders__btn main-btn">
              {{ $t("continueShopping") }}
            </button></router-link
          >
        </TabBlock>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
export default {
  components: {
    TabBlock,
    TabItem,
  },
  data() {
    return {
      selectedTab: null,
      orderas: [],
      tabs: [
        { id: 1, name: `account.currentOrders`, value: this.orders.length },
        { id: 2, name: `account.allOrders`, value: this.orders.length },
      ],
    };
  },
  watch: {
    orders() {
      this.orderas = this.orders.sort((a, b) => b.id - a.id);
    },
  },
  props: {
    orders: {
      type: Array,
    },
  },
  created() {
    this.selectedTab = this.tabs[0].id;
    this.orderas = this.orders.sort((a, b) => b.id - a.id);
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    formatPrice(currency) {
      return currency?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸";
    },
  },
};
</script>

<style lang="scss">
.personal-orders {
  .tab-items {
    margin: 0 0 22px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 32px 0;
    }
  }
  .tab-link {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #727272;
  }
  &__block {
    margin: 0 0 42px 0;
    &.line {
      padding: 0 0 57px 0;
      margin: 0 0 60px 0;
      border-bottom: 1px solid #c4c4c4;
    }
  }
  &__item {
    @extend .base-text-600;
    color: #727272;
    span {
      color: #202020;
    }
    &:not(:last-child) {
      margin: 0 0 21px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 18px 0;
      }
    }
    &:first-child {
      span {
        text-transform: uppercase;
      }
    }
    &:last-child {
      display: flex;
      align-items: flex-start;
      gap: 10px;
    }
  }
  &__btn {
    padding: 0 25px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }
  &__products {
    display: inline-block;
  }
}
</style>